import * as THREE from 'three';
import React, { useRef, useState, useEffect, useMemo, Suspense } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { ScrollControls, useScroll } from '@react-three/drei';
import { easing } from 'maath';
import { carouselData, smallCarouselData2, smallCarouselData1 } from './data';
import './styles.css';
import './util';

const isMobile = window.matchMedia("(max-width: 768px)").matches;
const textureCache = new Map(); // Кэш текстур видео

const Card = React.memo(({ url, link, scale = 1, marginY = 0, marginX = 0, radius, ...props }) => {
  const ref = useRef();
  const videoEl = useRef(null); // Используем useRef для видео
  const [hovered, setHovered] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(1);
  const [videoTexture, setVideoTexture] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inView, setInView] = useState(true); // Для отслеживания видимости
  const [isPlaying, setIsPlaying] = useState(false); // Флаг для отслеживания состояния видео

  // Мемоизация видео элемента
  const vid = useMemo(() => {
    const videoElement = document.createElement('video');
    videoElement.crossOrigin = 'anonymous';
    videoElement.loop = true;
    videoElement.playsInline = true;
    videoElement.muted = true;
    videoElement.preload = 'auto';
    return videoElement;
  }, []); // Создаётся только один раз при монтировании компонента

  useEffect(() => {
    if (!videoEl.current && inView) {
      if (vid.src !== url) {
        vid.src = url;
        videoEl.current = vid;
      }
    }
  }, [url, inView, vid]);

  useEffect(() => {
    if (videoEl.current && inView) {
      const handleLoadedMetadata = () => {
        const ratio = videoEl.current.videoWidth / videoEl.current.videoHeight;
        setAspectRatio(ratio || 1);
        setLoading(false);
      };

      videoEl.current.addEventListener('loadedmetadata', handleLoadedMetadata);
      videoEl.current.play().catch(e => console.error('Error playing video:', e));

      // Используем кэш текстур, если он уже существует
      if (textureCache.has(url)) {
        setVideoTexture(textureCache.get(url));
        setLoading(false);
      } else {
        const texture = new THREE.VideoTexture(videoEl.current);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.generateMipmaps = false;
        textureCache.set(url, texture);
        setVideoTexture(texture);
      }

      return () => {
        videoEl.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [url, inView]);

  // Оптимизация вычислений с использованием счетчика кадров
  const frameCount = useRef(0);
  useFrame((state) => {
    frameCount.current++;
    if (frameCount.current % 10 === 0) {
      if (ref.current) {
        const { x, y, z } = ref.current.position;
        const screenPosition = new THREE.Vector3(x, y, z);
        screenPosition.project(state.camera);

        const distanceFromCamera = ref.current.position.z;
        const isInFront = distanceFromCamera > 0;

        setInView(Math.abs(screenPosition.x) < 1 && Math.abs(screenPosition.y) < 1);

        if (videoEl.current && isInFront && !isPlaying) {
          videoEl.current.play();
          setIsPlaying(true);
        } else if (videoEl.current && !isInFront && isPlaying) {
          videoEl.current.pause();
          setIsPlaying(false);
        }
      }
    }
  });

  useFrame((_, delta) => {
    const targetScale = hovered ? scale * 0.9 : scale * 0.8;
    easing.damp3(ref.current.scale, targetScale, 0.1, delta);
  });

  const handleLink = () => {
    if (link) window.open(link, '_blank');
  };

  return (
    <group
      ref={ref}
      {...props}
      onClick={handleLink}
      onPointerOver={e => {
        e.stopPropagation();
        setHovered(true);
      }}
      onPointerOut={e => {
        e.stopPropagation();
        setHovered(false);
      }}
    >
      {loading || isMobile && (
        <mesh position={[marginX, marginY, 0.01]}>
          <planeGeometry args={[1, 1]} />
        </mesh>
      )}
      {videoTexture && !loading && inView && (
        <>
          <mesh position={[marginX, marginY, 0.0001]}>
            <planeGeometry args={[aspectRatio, 1]} />
            <meshBasicMaterial side={THREE.BackSide} map={videoTexture} />
          </mesh>
          <mesh position={[-0.5 + marginX, marginY, -0.0001]}>
            <planeGeometry args={[aspectRatio, 1]} />
            <meshBasicMaterial
              side={THREE.FrontSide}
              transparent
              opacity={0.25}
              map={videoTexture}
            />
          </mesh>
        </>
      )}
    </group>
  );
});

function Carousel({ radius = 1.4, smallCarouselData = [], scale = 0.8 }) {
  const totalItems = smallCarouselData.length;
  const angleStep = (Math.PI * 2) / totalItems;
  const distanceFactor = 0.9;
  const bend = 0.07;

  return (
    <group>
      {smallCarouselData.map(({ file, link, scale: itemScale, marginY, marginX }, i) => {
        const angle = i * angleStep;
        const x = Math.sin(angle) * radius * distanceFactor;
        const z = Math.cos(angle) * radius * distanceFactor;
        const bendAngle = (angle / (Math.PI * 2)) * (Math.PI * 2 * bend);
        const y = Math.sin(bendAngle) * 0.5;

        return (
          <Card
            key={i}
            url={file}
            link={link}
            scale={itemScale * scale}
            radius={radius}
            position={[x, y, z]}
            rotation={[Math.sin(bendAngle) * 0.2, Math.PI + angle + 0.3, 0]}
            marginY={marginY}
            marginX={marginX}
          />
        );
      })}
    </group>
  );
}

function Rig({ children }) {
  const ref = useRef();
  const scroll = useScroll();

  useFrame((state, delta) => {
    ref.current.rotation.y = -scroll.offset * (Math.PI * 2);

    state.events.update();

    easing.damp3(
      state.camera.position,
      [scroll.offset * 4 - state.pointer.x * 4, state.pointer.y + 1.5, 10],
      0.1,
      delta
    );
    state.camera.lookAt(0, 0, 0);
  });

  return <group ref={ref}>{children}</group>;
}

export const App = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Canvas
        background="transparent"
        camera={{ position: [0, 0, isMobile ? 70 : 100], fov: isMobile ? 45 : 24 }}
        gl={{
          antialias: !isMobile, 
          pixelRatio: Math.min(window.devicePixelRatio, isMobile ? 1 : 2),
          toneMapping: isMobile ? THREE.NoToneMapping : THREE.ACESFilmicToneMapping,
          preserveDrawingBuffer: false,
          powerPreference: "low-power" // Оптимизация для мобильных
        }}
      >
        <ScrollControls horizontal vertical infinite pages={2} damping={0.1}>
          <Rig>
            <group position={[0, 0.6, 0]}>
              <Carousel smallCarouselData={smallCarouselData2} radius={1.6} />
            </group>
            <group position={[0, -0.2, 0]}>
              <Carousel smallCarouselData={carouselData} radius={2.4} />
            </group>
            <group position={[0, -1, 0]}>
              <Carousel smallCarouselData={smallCarouselData1} radius={1.5} />
            </group>
          </Rig>
        </ScrollControls>
      </Canvas>
    </Suspense>
  );
};
