export const defaultVideoURL = (originalURL) => {
  return `https://superzubel-proxy.ash-vosem.workers.dev/?url=${encodeURIComponent(originalURL)}`;
};

export const carouselData = [
  {
    // 1
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/0d42f732e43a5d2fe93b0311d609b527/downloads/default.mp4'),
    link: 'https://www.youtube.com/watch?v=tVth-TyDoDY',
    scale: 1.8,
    marginY: 0,
    marginX: 0
  },
  {
    // 2
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/cf06df2b94d325e4c58d18f1a4bed5d4/downloads/default.mp4'),
    link: 'https://www.youtube.com/watch?v=tVth-TyDoDY',
    scale: 2,
    marginY: 0,
    marginX: 0
  },
  {
    // 3
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/001522027370dc4535bd20b171977ccc/downloads/default.mp4'),
    link: 'https://www.youtube.com/watch?v=llZ3JsfR50o',
    scale: 2,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 4
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/df73106770b132d5d956651012d503dd/downloads/default.mp4'),
    link: 'https://www.instagram.com/reel/CsOgd-ivpG2/?igsh=eGw0MWkzbGZ0ZWFk',
    scale: 2,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 8
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/3ed720b411370834943bb09a6db9dfae/downloads/default.mp4'),
    scale: 1.6,
    marginY: 0.1,
    marginX: 0
  },
  { 
    // 20_1
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/c41a61eec6b15ede7a91430136e4765f/downloads/default.mp4'),
    link: 'https://youtu.be/V5nU2MjD8KI?si=mcVZWNWNohkF-YpO',
    scale: 1.8,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 7
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/3b9a4f90e62b7db6f89992baf912caf4/downloads/default.mp4'),
    link: 'https://www.youtube.com/watch?v=daH9iAd0Bes',
    scale: 1.7,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 16
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/7cdd498d2435d781dcd565b671446807/downloads/default.mp4'),
    link: 'https://www.youtube.com/watch?v=daH9iAd0Bes',
    scale: 3,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 13
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/956ec5af529df0da4469e9edca1d8198/downloads/default.mp4'),
    link: 'https://www.youtube.com/watch?v=tVth-TyDoDY',
    scale: 2,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 6
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/621a461de891ec77f8e53ba3c7d17bea/downloads/default.mp4'),
    link: 'https://www.youtube.com/watch?v=daH9iAd0Bes',
    scale: 1.4,
    marginY: 0.2,
    marginX: 0
  },
  {
    // 26
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/4d9ed2d0dabe699a9f654b0378702956/downloads/default.mp4'),
    link: 'https://www.instagram.com/reel/C2XgyLdMeYS/?igsh=bWF0eHlucm1wbzZ5',
    scale: 3,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 19_1
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/03cc724c23292012b26b2fc4e5e682c3/downloads/default.mp4'),
    link: 'https://youtu.be/V5nU2MjD8KI?si=mcVZWNWNohkF-YpO',
    scale: 1.8,
    marginY: 0.1,
    marginX: 0
  },
]

// Маленькая верхняя карусель
export const smallCarouselData2 = [
  {
    // 16_1
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/09f98868eadf3b6a8e4298df77e41177/downloads/default.mp4'),
    link: 'https://www.instagram.com/reel/C8Hyob4MEC6/?igsh=MXVuYWo3NnhwY2NoNw==',
    scale: 2.4,
    marginY: 0.2,
    marginX: 0
  },
  {
    // 5
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/b5978d5f255fd0332d050d5b12e5ea6d/downloads/default.mp4'),
    link: 'https://www.instagram.com/reel/CsOgd-ivpG2/?igsh=eGw0MWkzbGZ0ZWFk',
    scale: 1.6,
    marginY: -0.1,
    marginX: 0
  },
  {
    // 14_1
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/5b67f52242025c7b7c378b4f6a4d412c/downloads/default.mp4'),
    link: 'https://www.instagram.com/reel/C_YPcz5P9Xs/?igsh=MWNmM3lieDU0ZGxseA==',
    scale: 1.4,
    marginY: 0.1,
    marginX: -0.1
  },
  {
    // 25
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/a384360a1330ed3ae2f19d29a7520009/downloads/default.mp4'),
    link: '',
    scale: 2,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 18_1
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/a4c571ec26e2c60af8016f2a3efcc9be/downloads/default.mp4'),
    link: 'https://youtu.be/V5nU2MjD8KI?si=mcVZWNWNohkF-YpO',
    scale: 1.4,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 24
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/0a8ee1d6822aa8c7acd8e4a47aa46d2b/downloads/default.mp4'),
    link: '',
    scale: 1.8,
    marginY: 0.2,
    marginX: 0
  },
  {
    // 10
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/a80f850487b49fb6d73f370f30b035d9/downloads/default.mp4'),
    link: 'https://www.instagram.com/reel/C3QWk4DIFpf/?igsh=MWlkbjV4czh3dXdmZw==',
    scale: 1.7,
    marginY: 0.2,
    marginX: 0
  },
]

// Маленькая нижняя карусель
export const smallCarouselData1 = [
  {
    // 9
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/b53e12be2c3d5df640b4cf365ccb0ad1/downloads/default.mp4'),
    link: 'https://www.youtube.com/watch?v=axziMZNGnLs',
    scale: 1.6,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 11
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/0899babe7bb74e377f735a1821867b2a/downloads/default.mp4'),
    link: 'https://www.instagram.com/reel/C3QWk4DIFpf/?igsh=MWlkbjV4czh3dXdmZw==',
    scale: 1.8,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 12
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/51e22c98d9ee1cdafbf8176a92595bea/downloads/default.mp4'),
    link: 'https://www.instagram.com/reel/C3QWk4DIFpf/?igsh=MWlkbjV4czh3dXdmZw==',
    scale: 1.6,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 17
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/0ee47419ac5aab6aeedc57a17b2f3497/downloads/default.mp4'),
    link: 'https://www.instagram.com/reel/C8Hyob4MEC6/?igsh=MXVuYWo3NnhwY2NoNw==',
    scale: 2.5,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 21_1
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/a19e61c501c80ac5c444a9bfb1478587/downloads/default.mp4'),
    link: 'https://youtu.be/V5nU2MjD8KI?si=mcVZWNWNohkF-YpO',
    scale: 1.9,
    marginY: -0.2,
    marginX: 0
  },
  {
    // 15
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/d97d2279563ec868fb3747bebfd295f4/downloads/default.mp4'),
    link: 'https://www.instagram.com/reel/C_YPcz5P9Xs/?igsh=MWNmM3lieDU0ZGxseA==',
    scale: 1.4,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 22_1
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/688fad07007c7b132068866d8d7e2fee/downloads/default.mp4'),
    link: 'https://youtu.be/V5nU2MjD8KI?si=mcVZWNWNohkF-YpO',
    scale: 1.4,
    marginY: 1,
    marginX: 0
  },
  {
    // 27
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/91c1f29e15b676a80f72fa744014d999/downloads/default.mp4'),
    link: '',
    scale: 2.4,
    marginY: 0.1,
    marginX: 0
  },
  {
    // 23
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/3058841c5cf88325a0eb257373e2080e/downloads/default.mp4'),
    link: 'https://youtu.be/V5nU2MjD8KI?si=mcVZWNWNohkF-YpO',
    scale: 1.4,
    marginY: -0.5,
    marginX: 0
  },
  {
    // 15_1
    file: defaultVideoURL('https://customer-qih66zhzzm3q0ys7.cloudflarestream.com/1d6fb39c869026906f1986156cce57ff/downloads/default.mp4'),
    link: 'https://youtu.be/V5nU2MjD8KI?si=mcVZWNWNohkF-YpO',
    scale: 1.6,
    marginY: -0.5,
    marginX: 0
  },
]